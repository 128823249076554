@import "core/bootstrap-mod";


// CORE COMPONENTS
@import "theme/base/myvariables";
@import "theme/base/grids";
@import "theme/base/typo";
@import "theme/base/buttons";
@import "theme/base/forms";
@import "theme/base/icons";


// FUNCTIONAL COMPONENTS
@import "theme/components/component.accordion";
@import "theme/components/component.backtop";
// @import "theme/components/component.calendar";
@import "theme/components/component.card"; 
@import "theme/components/component.collapse"; // base for cookie consent  
// @import "theme/components/component.domino";
@import "theme/components/component.dropdown";
@import "theme/components/component.partners";
@import "theme/components/component.datarangepicker";
@import "theme/components/component.filter"; 
@import "theme/components/component.result";
@import "theme/components/component.scrollableTable";
// @import "theme/components/component.hero";
// @import "theme/components/component.news";  
// @import "theme/components/component.orderedlist";
@import "theme/components/component.pagination";  
// @import "theme/components/component.stacktable"; // replaced by U:\marc\WEBDEV/Alternatief Stacktable.txt
// @import "theme/components/component.slickslider";
// @import "theme/components/component.search";
// @import "theme/components/component.toast"; 
// @import "theme/components/component.fancybox";
    // Opgelet : theme/components/component.fancybox is gekopieerd van jquery.fancybox.css (gedownload in Fancybox 3). 
    // Opgelet: de regels bij @supports in jquery.fancybox.css werden in comment gezet wegens browser-error ivm. MIMI-types. 
    // Deze zijn blijkbaar enkel nodig indien "captions" getoond worden.


// Partials
@import "theme/partials/partials.footer";
@import "theme/partials/partials.header";
@import "theme/partials/partials.nav";
@import "theme/partials/partials.madeby";


// Cookie consent gaat samen met:
//      src\assets\vendor\util.js
//      src\assets\vendor\modal.js
//      src\twig\components\_cookieconsent.twig
@import "theme/components/_component.cookieconsent.scss";


// Vendor
@import "../vendor/swiper-bundle.min"; 
@import "theme/components/component.timespinner";  // https://jsfiddle.net/terryyounghk/2oomnchr/


//VARIABLES
$white: #FFF;
$gray: #444;
$blue: #21ACE7;
$yellow: #FFC26B;
$black-3: rgba(0,0,0,.3);
$font-size-lg: 18px;

//MIXINS
@mixin animate{
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -ms-transition: 0.4s;
    transition: 0.4s;
    -webkit-animation-timing-function: linear;
    -moz-animation-timing-function: linear;
    -ms-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

*{
  box-sizing: border-box;
}