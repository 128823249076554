footer{
    .container{
		padding-top: 1rem;
		padding-bottom: 1.1rem;
	}
    @include media-breakpoint-only(xs){
        .container{
            padding-top: .5rem;
            padding-bottom: .5rem;
            padding-left: 0;
            padding-right: 0;
        }  
    }
    .logo{
        display: block;
        margin-bottom: 1rem;
        img{
            width: 9rem;
        }
    }
    a{
        color: $color1;
        &:hover{
            text-decoration: underline;
        }
    }
    ul.socials{
        a{
            background-color: $color1;
            // outline: 3px solid $color3;
            border-radius: 50%;
            display: inline-block;
            height: 1.95rem;
            width: 1.95rem;
            margin-right: .35rem;
            transition: background-color .15s ease-in-out;
            svg{
                width: 100%;
                height: 100%;
                padding: .35rem;
                fill: $white;
            }
            &:hover{
                background-color: $orange;
            }
        }
    }
    .adres{
        display: flex;
        background-color: #F0F0F0;
        .container{
            padding: 0;
            position: relative;
            &:before{
                content: '';
                background-color: $orange;
                position: absolute;
                z-index: 99999;
                width: calc((100vw - 1300px) / 2);
                left: calc(((100vw - 1300px) / 2) * -1);
                height: 100%;
            }
            @include media-breakpoint-down(lg) {
                &:before{
                    width: calc(100vw - 650px) !important;
                    left: calc(((100vw - 650px)) * -1) !important;
                }
            }
        }
    }
    // CTA ADRES 
    .cta--adres{
        background-color: #F0F0F0;
        display: flex;
        justify-content: space-between;
        font-size: 0.9rem;
        .item{
            position: relative;
            // padding: 1.5rem 0 1.5rem 3rem;
            padding: 1.4rem 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;
            &:first-child{
                position: relative;
                z-index: 1;
                display: flex;
                flex-wrap: nowrap;
                max-width: 20%;
                margin-right: 40px;
                &:after{
                    position: absolute;
                    top: 0;
                    right: -40px;
                    width: 40px;
                    height: 100%;
                    overflow: hidden;
                    content: '';
                    // background: url('../../assets/images/bow.svg') left center no-repeat;
                    background: url('../../assets/images/bow.svg') -0.5px center no-repeat;
                    // outline: 1px dashed black;
                    background-size: contain;
                }
            }
            .row > div{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
        }
        a{ 
            font-weight: 600;
            color: $orange;
        }
        a[href^="tel:"] {
            color: $orange;
            &:hover{
                color: $orange;
            }
        }
        @include media-breakpoint-down(md) {
            align-items: flex-start;
            .item{
                padding: 1.5rem;
                justify-content: space-around;
                .row{
                    flex-direction: column;
                }
                &:last-child{
                    flex:3;
                    flex-direction: row;
                    > div{
                        padding: .5rem;
                    }
                }
            }
        }
        @include media-breakpoint-only(xs){
            .item{
                padding: 1.5rem 1.0rem;
                &:last-child{
                    flex:1;
                    flex-direction: column;
                    padding: 1.5rem 0rem;
                    // > div{
                    //     padding: .5rem 1.5rem;
                    // }
                }
            }
            a{
                text-decoration: underline;
            }
            img{
                width: 10rem;
            }
            address{
                margin-bottom: 0;
                line-height: 120%;
            }
        }
    }
}




