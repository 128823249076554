
main{
    padding-top: 2rem;
    padding-bottom: 2rem;
    background: url('../images/bg-curves.svg') no-repeat center top;
    background-size: 300%;
}
@include media-breakpoint-up(xl) {
    .container{
        max-width: 1300px;
    }
}
@include media-breakpoint-up(md) {
    main{
        background-size: 2200px;
    }
    // .sticky{
    //     position: sticky;
    //     top: 0;
    // }
}
@include media-breakpoint-only(xs) {
    .border-right{
        border-right-width: 0 !important;
    }
}
// TEMPLATE CODE FOR MEDIA QUERIES
//@include media-breakpoint-up(xs) {}
//@include media-breakpoint-up(sm) {}
//@include media-breakpoint-up(md) {}
//@include media-breakpoint-up(lg) {}
//@include media-breakpoint-up(xl) {}


//@include media-breakpoint-down(xs) {}
//@include media-breakpoint-down(sm) {}
//@include media-breakpoint-down(md) {}
//@include media-breakpoint-down(lg) {}
// No media query necessary for xl breakpoint as it has no upper bound on its width


//@include media-breakpoint-only(xs) {}
//@include media-breakpoint-only(sm) {}
//@include media-breakpoint-only(md) {}
//@include media-breakpoint-only(lg) {}
//@include media-breakpoint-only(xl) {}
