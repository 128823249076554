header{
	background-color: $color4;
	.container{
		padding-top: 2rem;
		padding-bottom: 2rem;
	}
	.navbar-brand{
		img, svg{
			width: 12rem;
		}
	}
	.account{
		
		font-family: Gazpacho Black,sans-serif;
		font-style: normal;
		font-weight: 400;
		color: $orange;
		&.account-aangemeld-nee{ // Deze klasses worden getoond indien {% set layoutAanmelden = 'class' %} NIET wordt toegevoegd in de .twig-file
			// momenteel niet meer in gebruik maar laten staan als backup of documentatie
			display: none; // 
			width: 100%;
			text-align: right;
			margin-bottom: .7rem;

		}
		&.account-aangemeld-ja {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-left: 3rem;
		}
		@include media-breakpoint-up(md) {
			&--info{
				text-align: right;
			}
		}
		&--image{
			img{
				width: inherit;
				max-height: 80px;
				border-radius: 50%;
			}
		}
		a{
			&:hover{
				text-decoration: underline;
			}
		}
	}
	.statusAanmelden{ // Deze klasses worden toegevoegd indien {% set layoutAanmelden = 'class' %} WEL wordt toegevoegd in de .twig-file
						// momenteel niet meer in gebruik maar laten staan als backup of documentatie
		.account-aangemeld-nee{
			display: block;
		}
		.account-aangemeld-ja{
			display: none !important;
		}
	}
}
@media only print {
    header {
        position: inherit;
    }
	main, #main{
		margin-top: 0 !important;
	}
	.btn{
		display: none;
	}
}
@include media-breakpoint-down(md) {
	header{
		position: static;
		top: 0;
		width: 100%;
		.container{
			padding-top: 0;
			padding-bottom: 0;
			.navbar-brand{
				padding-top: 0.5rem;
				padding-bottom: 0.5rem;
			}
		}
	}
}
@include media-breakpoint-only(xs) {
	header{
		.navbar-brand{
			img{
				width: 9rem;
			}
		}
		.account{
			&--info{
				> div{
					margin-bottom: .3rem;
				}
			}
		}
		.account--image{
			display: none;
		}
	}
}
