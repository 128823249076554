.filter{
    display: flex;
    flex-wrap: nowrap;
    align-items: end;
    > div:not(.innerfilter){
        padding-left: .5rem;
        padding-right: .5rem;
        &:first-child{
            padding-left: 0;
        }
        &:last-child{
            padding-right: 0;
        }
    }
    .input-group{
        align-items: center;
        input[type=text]{
            position: relative;
            z-index: 99;
            background-color: transparent !important;
        }
        .input-group-append{
            position: absolute;
            z-index: 9;
            right: 1rem;
        }
    }
    input[type=text]{
        border: 1px solid #D5D4D5 !important;
        background-color: #fff !important;
        border-radius: 4px !important;
        font-size: 0.95rem;
        &:hover{
            cursor: pointer;
        }
    }
    .text-muted{
        font-size: 0.9rem;
        color: #94A3B8 !important;
    }
    .c-dropdown{
        .c-dropdown__inner{
            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
            li{
                border-bottom:1px solid $gray-200;
            }
        }
    }
    .dropdown{
        margin-top: 0.5rem;
            background-color: $orange;
        .btn{
            background-color: $white;
            border: 1px solid #eee;
            width: 100%;
            text-align: left;
        }
        .dropdown-menu{
            margin-top: 0;
            background-color: #F8FAFC;
            border: 1px solid #eee;
            box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
        }
    }
    &--vertical{
        display: block;
        padding: 0;
        outline: 1px solid #F0F0F0;
        .innerfilter{
            > div{
                 margin-bottom: 1rem;
            }
        }
    }
    &--reserveren{
        width: 100%;
        justify-content: space-between;
        padding: 0;
        > div{
            flex-basis: 100%;
        }
        .centerheight{
            height: 42px;
            display: flex;
            align-items: center;
        }
        @include media-breakpoint-down(sm) {
            flex-wrap: wrap;
            > div{
                flex-basis: 100%;
                margin-bottom: 2rem;
                padding-left: 0 !important;
                padding-right: 0 !important;
                &:first-child{
                    padding-left: 0;
                }
                &:last-child{
                    padding-right: 0;
                }
            }
        }
    }
}
.filter-label{
    display: block;
    font-weight: bold;
    font-size: 0.88rem;
    line-height: normal;
}
.filter-sublabel {
    display: block;
    font-size: 0.84rem;
    line-height: normal;
    margin-bottom: .5rem;
}


// DATE RANGEPICKER - https://www.daterangepicker.com/
.daterangepicker{
	background-color: #fafafa;
    margin-top: -1px;
    border-radius: 0;
    border: 1px solid #eee;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
    font-family: inherit;
    &:before, &:after{
        display: none;
    }
    .drp-calendar{
	    background-color: $white;
        border: none !important;
    }
    .calendar-table {
        table{
            thead{
                tr{
                    display: flex;
                    flex-wrap: nowrap;
                    margin-bottom: 1.5rem;
                    th.prev{ order: 2;}
                    th.month{ 
                        order: 1; 
                        margin-right: auto;
                        font-family: Gazpacho Black,sans-serif;
                        font-style: normal;
                        font-weight: 400;
                    }
                    th.next{ order: 3;}
                }
            }
            tbody{
                display: block;
            }
            th, td{
                font-size: .9rem;
                color: $color1;
            }
            th{
                // background-color: red;
            }
        }
    }
    .drp-buttons{      
	    background-color: $white;
        .btn{
            padding: .375rem .75rem;
            font-size: 1rem;
            font-weight: normal;
        }
        .btn.cancelBtn{
            padding-left: 0;
        }

    }
    .ranges{
        padding: 10px 15px 12px;
        color: $color1;
        .intro{
            display: inline-block;
            padding: 0 10px 20px;
            font-weight: bold;
            max-width: 200px;
        }
        ul{
            li{
                margin-bottom: 3px;
                background-color: $white;
                padding: 12px 12px;
                font-size: .9rem;
                // border: 1px solid transparent;
                border: 1px solid #F0F0F0;
                transition: border 0.3s ease;
                &:hover{
                    background-color: $white;
                    border: 1px solid $orange;
                    color: $orange;;
                }
                &.active{
                    background-color: $orange;
                    border: 1px solid $orange;
                    color: #fff;
                }
            }
        }
    }
    td.start-date.end-date,
    td.available:hover, th.available:hover {
        border-radius: 0 !important;
        // background-color: #E2E8F0;
        background-color:  #f0f0f0 ;
        color: #444;
    }
}


// ONDERSTAANDE STIJLEN ZIJN GEKOPIEERD VIA INSPECT ELEMENT
.daterangepicker td.start-date {
    border-radius: 4px 0 0 4px;
    background-color:  #f0f0f0 !important;
    color: $color1 !important;
}
.daterangepicker td.end-date {
    border-radius: 0 4px 4px 0;
    background-color:  #f0f0f0 !important;
    color: $color1 !important;
}
.daterangepicker td.in-range {
    background-color:  #f0f0f0 !important;
}



// NIEUW NA UPDATE TINE
.daterangepicker td.in-range {
    background-color: #FFA587 !important;
    color: white !important;
}
    .daterangepicker td.in-range.hover {
        background-color: #FDB9A3 !important;
    }
.daterangepicker td.end-date, .daterangepicker td.start-date {
    background-color: #ff6a39 !important;
    color: white !important;
}
.daterangepicker td.available:hover, .daterangepicker th.available:hover {
    background-color: #EF460E !important;
    color: white !important;
}
.daterangepicker td.off {
    color: #999 !important;
}
.daterangepicker td.ends {
    visibility: hidden;
}

.daterangepicker td.off, .daterangepicker td.off.end-date, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date {
    background-color: #fff !important;
}