// SCROLLABLE TABLE WITH FIXED HEADER AND COLUMN
.tableFixed{
  margin-bottom: 0.3rem;
}
@include media-breakpoint-only(xs) {
    .tableFixed{
        max-width: 100%;
        max-height: calc( 100vh - 200px);
        overflow: scroll;
        position: relative;
        margin-bottom: 1rem;
        table {
          position: relative;
          border-collapse: collapse;
        }
        td, th {
          padding: 0.25em;
        }
        thead th {
          position: -webkit-sticky;  //for Safari 
          position: sticky;
          top: 0;
          background: $color4;
        }
    
        thead th:nth-child(1) {
          left: 0;
          top: 0;
          z-index: 2;
        }
        tbody th {
            position: -webkit-sticky; // for Safari 
            position: sticky;
            z-index: 1;
            &:nth-child(1){
                background-color: white;
                left: 0;
            }
        }
    }
}