.dashboard{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 1.1rem 0.4rem 0.8rem 1rem ;
    margin-bottom: 1rem;
    &.month{
        width: 100%;
        a.btn{
            &:hover{
                background-color: $white;
            }
        }
    }
    &--nav{
        margin: 0;
        padding: 0;
        list-style: none;
        display: inline-flex;
        li{
            a{
                display: block;
                background-color: $color5;
                // background-color: transparent;
                position: relative;
                margin: 0;
                border-radius: 4px;
                &:hover{
                    color: $orange;
                }
            }
            &.active a{
                z-index: 1;
                background-color: $orange;
                color: $white;
                margin-left: -4px;
                // margin-right: -4px;
                &:hover{
                    background-color: $orange;
                    color: $white;
                }
            }
        }
    }

    // SWITCH - TOON WEERGAVE ALS
    .material-switch{
        display: inline-flex;
        align-items: center;
        width: auto;
        span{
            width: auto;
            margin-bottom: 0;
            &.active{
                color: $orange !important;
            }
        }
        > {
            input[type="checkbox"] {
              display: none;
            }
          
            label {
                margin-bottom: 0;
              cursor: pointer;
              height: 0px;
              position: relative;
              width: 40px;
          
              &::before {
                background-color: $orange;
                // box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
                box-shadow: none;
                border-radius: 8px;
                content: '';
                height: 16px;
                margin-top: -8px;
                position: absolute;
                opacity: 0.3;
                transition: all 0.4s ease-in-out;
                width: 40px;
              }
          
              &::after {
                background-color: $orange;
                border-radius: 16px;
                // box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
                content: '';
                height: 24px;
                left: -4px;
                margin-top: -8px;
                position: absolute;
                top: -4px;
                transition: all 0.3s ease-in-out;
                width: 24px;
              }
            }
          
            input[type="checkbox"]:checked + label {
              &::before {
                // background: inherit;
                background-color: $orange;
                opacity: 0.3;
              }
          
              &::after {
                // background: inherit;
                background-color: $orange;
                left: 20px;
              }
            }
          }
    }



    @include media-breakpoint-down(md) {
        flex-direction: column;
        align-items: flex-start;
        padding: 0 ;
        &.month{
            flex-direction: row;
            align-items: center;
            padding: 0.4rem 0.4rem 0.4rem 1rem ;
            span{
                width: auto;
            }
            .dashboard--nav{
                margin-top: 0;
            }
        }
        &--nav{
            display: flex;
            margin-top: 0.5rem;
            li{
                flex: 1;
            }
        }
        span{
            width: 100%;
            margin-bottom: .5rem;
        }
    }
}
.result{
    margin-bottom: 2rem;
    &--title{
        flex-basis: 100%;
        background-color: $color4;
        padding: 0.5rem 1rem ;
        h2, h3{
            margin: 0;
            font-size: 1.5rem;
        }
    }
    &--content{
        display: flex;
        justify-content: space-between;
        background-color: $white;
        border-radius: 4px;
        overflow: hidden;
        border: 1px solid $color5;
        &-image{
            background-color: $color5;
            min-width: 0; /* Hack om elementen die breder zijn dan de parent DIV deze te doen stretchen bvb owl-carousel of supergrote beelden */
        }
        &-shortinfo{
            flex-grow: 0;
            padding: 1.0rem;
            &-item{
                margin-bottom: 1rem;
            }
            .label{
                display: block;
                font-weight: 600;
            }
            span{
                display: block;
            }
        }
    }
    @include media-breakpoint-down(md) {
        &:last-child{
            // border: 3px solid red;
            margin-bottom: 0;
        }
        &--content{
            flex-direction: column;
        }
    }
}

// SWIPERS 
.swiper{
    .swiper-button-prev{
        &:after{
            font-family: inherit;
            content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxMCAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTggMi4xMDE1NkwyLjAxNDggOC4wODc2MiIgc3Ryb2tlPSIjRkZBRkFFIiBzdHJva2Utd2lkdGg9IjQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8cGF0aCBkPSJNOCAxNC4xMDMyTDIuMDE0OCA4LjExNzE5IiBzdHJva2U9IiNGRjZBMzkiIHN0cm9rZS13aWR0aD0iNCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=');
        }
    }
    .swiper-button-next{
        &:after{
            font-family: inherit;
            content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxMCAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIgMi4xMDE1Nkw3Ljk4NTIgOC4wODc2MiIgc3Ryb2tlPSIjRkZBRkFFIiBzdHJva2Utd2lkdGg9IjQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8cGF0aCBkPSJNMiAxNC4xMDMyTDcuOTg1MiA4LjExNzE5IiBzdHJva2U9IiNGRjZBMzkiIHN0cm9rZS13aWR0aD0iNCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=');
        }
    }
}
.swiper-day-navigation{
    margin-bottom: 1rem;
    border-radius: 5px;
    border: 1px solid rgba(71, 67, 68, 0.2);
    padding: .6rem 2rem !important;
    a{
        color: $color1;
        &:hover{
            text-decoration: none !important;
        }
    }
    .swiper-button-prev, .swiper-rtl .swiper-button-next{
        left: 0;
        width: 25px;
        // outline: 1px solid red;
        background: -moz-linear-gradient(left,  rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
        background: -webkit-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0) 100%);
        background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );
    }
    .swiper-button-next, .swiper-rtl .swiper-button-prev{
        right: 0;
        width: 25px;
        background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
        background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,1) 100%);
        background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
    }
    .swiper-slide{
        width: auto;
        font-size: 14px;
        padding: 0.3rem 0.5rem;
        border-radius: 3px;
        &:hover{
            background-color: $gray-200;
        }
    }
}
.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.swiper-pagination-bullet{
    --swiper-pagination-bullet-size: 16px;
    background: $white !important;
    &-active{
        background: $orange !important;
    }
}
.swiper-button-next:after, .swiper-button-prev:after{
    color: $orange;
}
.swiper-zaal-detail-month{
    border: 2px solid red;
    overflow: visible !important;
    overflow-x: clip !important; // werkt niet op safari
    .swiper-slide{
        flex-direction: column;
    }
    .swiper-button-next, .swiper-button-prev{
        margin-top: 0;
        top: 0;
    }
    .swiper-button-prev{
        left: auto;
        right: 50px;
        // &:after{
        //     font-family: inherit;
        //     content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxMCAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTggMi4xMDE1NkwyLjAxNDggOC4wODc2MiIgc3Ryb2tlPSIjRkZBRkFFIiBzdHJva2Utd2lkdGg9IjQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8cGF0aCBkPSJNOCAxNC4xMDMyTDIuMDE0OCA4LjExNzE5IiBzdHJva2U9IiNGRjZBMzkiIHN0cm9rZS13aWR0aD0iNCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=');
        // }
    }
    .swiper-button-next{
        // &:after{
        //     font-family: inherit;
        //     content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxMCAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIgMi4xMDE1Nkw3Ljk4NTIgOC4wODc2MiIgc3Ryb2tlPSIjRkZBRkFFIiBzdHJva2Utd2lkdGg9IjQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8cGF0aCBkPSJNMiAxNC4xMDMyTDcuOTg1MiA4LjExNzE5IiBzdHJva2U9IiNGRjZBMzkiIHN0cm9rZS13aWR0aD0iNCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=');
        // }
    }
}

// POPOVER - based on https://www.jqueryscript.net/tooltip/Minimal-CSS3-Animated-jQuery-Popover-Plugin.html
 $popover-triangle-size:          8px;
 $popover-triangle-right-offset:  16px;
//  $popover-triangle-left-offset:   16px;
 $popover-triangle-left-offset:   48%;

 .popover-wrapper {
   position: relative;
   display: block;
   height: 100%;
   > div{
     position: relative;
     height: 100%;
   }
   a{
       display: block;
       width: 100%;
       height: 100%;
       text-align: center;
       &:hover{
           cursor: pointer;
       }
   }
   .btn{
       max-width: 50%;
   }
 }
.popover-modal { 
    display: none;
    position: absolute;
    z-index: 9;
    left: 50%;// for positioning for mobile & tablets, see below under ".availability"
    // -webkit-transform: translateX(-50%);// for positioning for mobile & tablets, see below under ".availability"
    transform: translateX(-50%);// for positioning for mobile & tablets, see below under ".availability"
    bottom: 30px;
    border: 1px solid $gray-200;
    background-color: $white;
    margin-bottom: -4px;
    border-radius: 4px;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.3);
 }
 
 .popover-wrapper.open .popover-modal {
    -webkit-transform: scale(1), translateX(-50%);
            transform: scale(1), translateX(-50%);
    //  opacity: 1;
    display: inline-block;
 }
 
 .popover-wrapper.open .btn-dropdown { 
    outline: none; 
  }
 
.popover-modal:before {
    display: inline-block;
    position: absolute;
    bottom: -17px;
    left: $popover-triangle-left-offset;
    border: $popover-triangle-size solid rgba(0, 0, 0, 0);
    border-top-color: rgba(0, 0, 0, 0.2);
    content: "";
}

.popover-modal:after {
    display: inline-block;
    position: absolute;
    bottom: -14px;
    left: $popover-triangle-left-offset + 1;
    border: $popover-triangle-size - 1 solid rgba(0, 0, 0, 0);
    border-top-color: $color4;
    content: "";
}
 
 .popover-modal .popover-body {
    margin: 0;
    padding: 0;
    min-width: 295px;
 }
 
 .popover-modal .popover-body.popover-body-padded { 
     padding: 8px; 
    }

 .popover-modal .popover-header {
    padding: 8px;
    line-height: 19px;
    border-bottom: 1px solid $gray-200;
 }

 
 .availability-legende{
    ul{
        display: inline-flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        margin-bottom: 1rem;
        list-style: none;
        li{
            margin-right: 1rem;
            display: flex;
            align-items: center;
            &.green, &.orange, &.yellow{
                color: $color1 !important;
                &:before{
                    content: '';
                    display: inline-block;
                    margin-right: .3rem;
                    width: 16px;
                    height: 16px;
                    border-radius: 2px;
                }

            }
            &.green:before{
                background-color: #ADDAC7;
            }
            &.yellow:before{
                background-color: #FADC86;
            }
            &.orange:before{
                background-color: $orange;
            }
        }
        @include media-breakpoint-down(md) {
        }
    }
}
.availability{
    border: 1px solid #dee2e6!important;
    padding: 8px;
    margin-bottom: 20px;
    @include media-breakpoint-down(md) {
        border:  none!important;
        padding: 0;
    }
    .table{
        margin-bottom: 0;
    }
    .table th{
        padding-top: 0;
        padding-bottom: 0;
        height: 32px;
        vertical-align: middle;
        color: $color1;
    }
    .table td{
        padding: 3px 2px;
        height: 32px;
    }
    .date-mobile{
        display: none;
    }
    .table thead th{
        border: none;
        width: 25%;
        background-color: #F0F0F0;
        padding-left: 2px;
        &:first-child{
            padding-left: 12px;
        }
    }
    .table tbody tr{
        th, td{
            border:none;
        }
        th{
            white-space: nowrap;
        }
        td{
            &:last-child{
                padding-right: 0;
            }
        }
    }
    .table tbody tr:first-child{
        th, td{
            border-top: none;
        }
    }
    .table tbody th{
        font-weight: normal;
    }
    .availability-green, .availability-orange, .availability-yellow{
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 2px;
    }
    .availability-green{
        background-color: #ADDAC7;
    }
    .availability-yellow{
        background-color: #FADC86;
    }
    .availability-orange{
        background-color: $orange;
    }
    .daytime{
        &.vm:before{ content: 'Voormiddag';}
        &.nm:before{ content: 'Namiddag';}
        &.av:before{ content: 'Avond';}
        &.vm:after{ display: none;}
        &.nm:after{ display: none;}
        &.av:after{ display: none;}
    }


    // START AVAILABILITY VOOR ZAAL DETAIL
    &.availability-detail{
        .table thead{
            margin-bottom: 25px;
            tr{
                margin-bottom: 25px;
                // outline: 1px solid red;
            }
            th{
                // outline: 1px solid blue;
                background-color: $color5;
                margin: 3px 2px;
                width: auto;
                border-bottom: 2px solid white;
                &:last-child{
                    border-right: 4px solid #fff;
                }
            }
        }
        .week-mobile{
            display: none;
        }
    }
    // END AVAILABILITY VOOR ZAAL DETAIL

    @include media-breakpoint-down(md) {
        border: 0;
        .date-mobile{
            display: flex;
            align-items: center;  
            font-weight: bold;
            margin-bottom: .5rem;       
            &:after{
                flex: 1;
                content: '';
                height: 1px;
                margin-left: 1rem;
                background-color: rgba(71, 67, 68, 0.2);
            }
        }
        .availability-capacity{
            margin-left: 0 !important;
            margin-right: 0 !important;
            display: inline-flex;
            align-items: center;
            line-height: 100%;
            // outline: 1px solid red;
            &:before{
                content: url('../images/ico-user.svg');
                margin-right: .25rem;
                // height: 16px;
            }
        }
        .table thead{
            tr{
                position: relative;
            }
        }
        .table thead th{
            background-color: $white;
            white-space: nowrap;
            width: 30%;
            &:first-child{
                max-width: 0;
                width: 10%;
            }
            .date{
                display: none;
            }
        }
        .table tbody th{
            padding-left: 0;
            padding-right: 5px;
        }
        .daytime{
            &.vm:before{ display: none;}
            &.nm:before{ display: none;}
            &.av:before{ display: none;}
            &.vm:after{ display: block; content: 'VM';}
            &.nm:after{ display: block; content: 'NM';}
            &.av:after{ display: block; content: 'AV';}
        }
        // START AVAILABILITY VOOR ZAAL DETAIL
        &.availability-detail{
            .table{
                margin-bottom: 0;
                th{
                    height: auto;
                }
                tr{
                    // outline: 1px solid red;
                    display: flex;
                    align-items: center;
                    th, td{
                        flex: 1;
                        &:first-child{
                            flex: auto;
                            width: 70px;
                            max-width: 70px;
                            display: flex;
                            align-items: center;
                            // outline: 2px solid blue;
                        }
                    }
                }
                thead{
                    background-color: $color5;
                    tr{
                        margin-bottom: 0;
                    }
                    th{
                        // margin: 3px 0;
                        margin: 0;
                        padding:  0.1rem;
                        border-color: $color5 !important;
                        text-align: center;
                        // border-bottom: none;
                        // border-right-width: 0 !important;
                        .week{
                            display: none;
                        }
                        .week-mobile{
                            display: flex;
                            align-items: center;  
                            font-weight: bold;
                            margin-bottom: .5rem;       
                            &:after{
                                flex: 1;
                                content: '';
                                height: 1px;
                                margin-left: 1rem;
                                background-color: rgba(71, 67, 68, 0.2);
                            }
                        }
                        .date{
                            display: inline-block;
                            white-space: normal;
                            font-weight: 400;
                            font-size: 0.9rem;
                            line-height: 120%;
                        }
                    }
                }
            }
            .time{
                display: inline-flex;
                align-items: center;
                line-height: 100%;
                font-size: 0.8rem;
                &:before{
                    margin-right: 5px;
                    content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNiAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04IDIuMjAxNTZDNC40NjUzOCAyLjIwMTU2IDEuNiA1LjA2Njk0IDEuNiA4LjYwMTU2QzEuNiAxMi4xMzYyIDQuNDY1MzggMTUuMDAxNiA4IDE1LjAwMTZDMTEuNTM0NiAxNS4wMDE2IDE0LjQgMTIuMTM2MiAxNC40IDguNjAxNTZDMTQuNCA1LjA2Njk0IDExLjUzNDYgMi4yMDE1NiA4IDIuMjAxNTZaTTAgOC42MDE1NkMwIDQuMTgzMjggMy41ODE3MiAwLjYwMTU2MiA4IDAuNjAxNTYyQzEyLjQxODMgMC42MDE1NjIgMTYgNC4xODMyOCAxNiA4LjYwMTU2QzE2IDEzLjAxOTggMTIuNDE4MyAxNi42MDE2IDggMTYuNjAxNkMzLjU4MTcyIDE2LjYwMTYgMCAxMy4wMTk4IDAgOC42MDE1NlpNOCAzLjgwMTU2QzguNDQxODMgMy44MDE1NiA4LjggNC4xNTk3MyA4LjggNC42MDE1NlY4LjI3MDE5TDEwLjk2NTcgMTAuNDM1OUMxMS4yNzgxIDEwLjc0ODMgMTEuMjc4MSAxMS4yNTQ4IDEwLjk2NTcgMTEuNTY3MkMxMC42NTMzIDExLjg3OTcgMTAuMTQ2NyAxMS44Nzk3IDkuODM0MzEgMTEuNTY3Mkw3LjQzNDMxIDkuMTY3MjVDNy4yODQyOSA5LjAxNzIyIDcuMiA4LjgxMzc0IDcuMiA4LjYwMTU2VjQuNjAxNTZDNy4yIDQuMTU5NzMgNy41NTgxNyAzLjgwMTU2IDggMy44MDE1NloiIGZpbGw9IiM0NzQzNDQiLz4KPC9zdmc+Cg==');
                }
            }
        }
        // END AVAILABILITY VOOR ZAAL DETAIL
    }

    // POPOVER POSITING ON MOBILE, TABLETS & SMALL SCREENS
    @include media-breakpoint-down(lg) {
        .table{
            tr{
                position: relative; // new position for popover-modal;
            }
        }
        .popover-wrapper, .popover-wrapper>div{
            position: inherit;
            a{
                position: relative;
            }
            a:focus{                 
                &:after {
                    display: inline-block;
                    position: absolute;
                    z-index: 9999;
                    top: -6px;
                    left: $popover-triangle-left-offset - 15;
                    // left: 10px;
                    border: $popover-triangle-size - 1 solid rgba(0, 0, 0, 0);
                    border-top-color: $color4;
                    content: "";
                }
            }
            .popover-modal{
                margin-bottom: 3px;
            }
            .popover-modal:after, .popover-modal:before{
                display: none;
            }
        }
    }


    @include media-breakpoint-down(lg) {
        .table{
            tr{
                position: relative; // new position for popover-modal;
            }
        }
        .popover-wrapper, .popover-wrapper>div{
            position: inherit;
            a{
                position: relative;
                &:focus{                 
                    &:after {
                        display: inline-block;
                        position: absolute;
                        z-index: 9999;
                        top: -3px;
                        // left: $popover-triangle-left-offset + 1;
                        left: 30px;
                        border: $popover-triangle-size - 1 solid rgba(0, 0, 0, 0);
                        border-top-color: $color4;
                        content: "";
                    }
                }
            }
            .popover-modal{
                margin-bottom: 0;
                // left: inherit;
                left: 0;
                // right: 0;
                transform: translateX(0);
                // width: 75%;     
                &:after, &:before{
                    display: none;
                }
            }
        }
        &.availability-detail{
            .popover-wrapper, .popover-wrapper>div{
                a{
                    &:focus{                 
                        &:after {
                            left: 7px !important;
                        }
                    }
                }
            }
        }
    }
    @include media-breakpoint-only(lg) {
        .popover-wrapper, .popover-wrapper>div{
            a{
                &:focus{                 
                    &:after {
                        left: 60px;
                    }
                }
            }
        }
        .popover-modal{
            width: 75%;
        }
    }
    @include media-breakpoint-only(md) {
        .popover-wrapper, .popover-wrapper>div{
            a{
                &:focus{                 
                    &:after {
                        left: 40px;
                    }
                }
            }
        }
    }
}

.dataTable{
    thead{
        th{
            // background-color: #f8d7da;
            color: $white;
            background-color: $orange !important;
            border-color: $orange !important;
        }
        tr{
            th{
                border-right: 1px dotted lighten($orange, 20%) !important;
                font-size: 1.2rem;
                font-weight: 400;
                &:nth-last-child(2){
                    border-right: 0 !important;
                }
                &:first-child{
                    border-top-left-radius: 9px;
                }
                &:last-child{
                    border-top-right-radius: 9px;
                }
                &:not(:first-child){
                    text-align: center;
                }
                &:last-child{
                    border-right: 0 !important;
                }
                &.icon-search{
                    min-width: 20px;
                }
                &:not(.sorting_disabled){
                    padding-right: 25px !important;
                }
            }
        }
        .sorting{
            &:before, &:after{
                opacity: 0.5 !important;
                bottom: 0.7em !important;
            }
            &:before{
                right: 1.1em !important;
                // left: 0em !important;
                // right: inherit !important;
            }
            // &:after{
            //     left: 0.6em !important;
            //     right: inherit !important;
            // }
        }
        .sorting_asc{
            &:before{
                opacity: 1 !important;
            }
        }
        .sorting_desc{
            &:after{
                opacity: 1 !important;
            }
        }
    }
    tbody{
        tr{
            td{
                &:not(:first-child){
                    text-align: center;
                }
                &:last-child{
                    text-align: center;
                }
                &:first-child{
                    font-weight: 600;
                }
                &.dtr-control{
                    &:before{
                        background-color: $orange !important;
                        border-color: $orange !important;
                        box-shadow: none;
                        line-height: 1.2em;
                    }
                }
            }
        }
    }
    &.collapsed{
        tr{
            td{
                &:last-child{
                    text-align: left !important;
                }
                ul{
                    width: 100% !important;
                }
                li{
                    padding-top: 0.3rem !important;
                    padding-bottom: 0.3rem !important;
                    padding-left: .5rem !important;
                }
                .dtr-title{ font-weight: normal !important;}
            }
        }
    }
}
// myreservations - https://www.w3schools.com/howto/tryit.asp?filename=tryhow_js_sort_table_desc
// table.sortable{
// 	tr{
// 		th{
// 			padding-left: .5rem;
// 			padding-right: 1rem;
// 			&:after {
// 				float: right;
// 				content: ''; 
// 				background: url("../images/ico-sort-solid.svg") no-repeat center center;
// 				background-size: 40%;
// 				width: 25px;
// 				height: 25px;
// 				opacity: 0.2;
// 			}
// 			&:hover{
// 				cursor: pointer;
// 				background-color: rgba(0,0,0,0.03);
// 			}
// 		}
// 	}
// } 

// .sortable tr td:before{ display: none;}
// @media only screen and (max-width: 767px) {  
//   .sortable tr td:before{ display: block;}
//     .sortable{
//       tbody tr:first-child{
//         display:none;
//       }
//       tr {
//         display: block;
//         margin-bottom: 15px;
//         max-width: 100%;
//         td{
//           display: flex;
//           &:before{
//             font-weight: bold;
//             width: 25%;
//           }

//         }
//       }
//       tr.head {
//         display: none;
//       }
//       td, th {
//         display: block;
//         border: none;
//         padding: 3px 15px;
//         margin-bottom: -1px;
//         max-width: 100%;
//         border: 1px solid #ddd;
//       }
//     }
// }