.c-accordion {
	background-color: $white;
	width: 100%;
	display: block;
	padding-bottom: 1em;
}
.c-accordion__title {
	position: relative;
	cursor: pointer;
	transition: all 0.3s ease-out;
	&:before {
		content: '';
		width: 14px;
		height: 2px;
		background: $gray-600;
		position: absolute;
		top: calc(50% - 1px);
		right: 1rem;
		display: block;
		transition: all 0.3s;
	  }
	  &:after {
		content: '';
		width: 2px;
		height: 14px;
		background: $gray-600;
		position: absolute;
		top: calc(50% - 7px);
		right: calc(1rem + 6px);
		display: block;
		transition: all 0.3s;
	  }
	h4 {
		margin-bottom: 0;
		transition: all 0.3s ease-out;
		font-size: 1.2rem;
	}
	&.c-accordion-active { 
		h4 {
			color: $primary;
		}
		&:after {
			transform: rotate(90deg) translateX(-1px);
			background: $primary;
		}
		&:before {
			transform: rotate(180deg);
			background: $primary;
		}
	}
}
.c-accordion__content {
	// padding: 0.8rem 0 0 0;
	display: none;
}
