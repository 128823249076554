
$color1: #474344;
$color2: #334155;
$color3: #64748B; // 
$color4: #F8FAFC    ; // licht grijs
$color5: #F0F0F0    ; // licht grijs

$blue: #1D4ED8;
$orange: #FF6A39;
$green: #418e6e;

$white: #ffffff;
$light:#F7F7FB; // licht grijs
$dark: #333333; // dark grey

.color1{ color:$color1!important;}
.color2{ color:$color2!important;}
.color3{ color:$color3!important;}
.color4{ color:$color4!important;}
.color5{ color:$color4!important;}
.orange{ color:$orange!important;}
.green{ color:$green!important;}
.blue{ color:$blue!important;}
.white{ color:$white!important; h1, h2, h3, h4{ color: $white;}}

.bgcolor1{ background-image: none!important; background:$color1!important; color:white; h2, h3, a{color:white;}}
.bgcolor2{ background-image: none!important; background:$color2!important; color:white; h2, h3, a{color:white;}; .card{background-color: transparent;}}
.bgcolor3{ background-image: none!important; background:$color2!important; color:white; h2, h3, a{color:white;}; .card{background-color: transparent;}}
.bgcolor4{ background-image: none!important; background:$color4!important; }
.bgcolor5{ background-image: none!important; background:$color5!important; }
.bgwhite{ background-image: none!important; background:white!important; }
.bgorange{ background-image: none!important; background:$orange!important; color:white; h2, h3, a{color:white;}; .card{background-color: transparent;}}
.bgblue{ background-image: none!important; background:$blue!important; color:white; h2, h3, a{color:white;}; .card{background-color: transparent;}}
.bggreen{ background-image: none!important; background:$green!important; color:white; h2, h3, a, a:hover{color:white;}; .card{background-color: transparent;}}

@include media-breakpoint-only(xs) {
	.intro-on-mobile{
		margin-bottom: .5rem;
		background-color: $white;
		border-radius: 4px;
		padding: 1rem;
		color: $color1;
	}
}
@include media-breakpoint-up(md) {
	.bgwhite-up-md{ background-image: none!important; background:white!important; }
}

// $greyGradient:linear-gradient(218deg,#f2f2f2 10.66%,#ffffff 49.05%)!important;
// $whiteGradient:linear-gradient(90deg,rgba(255,0,0,0) 25%,rgba(255,0,0,0.5) 75%,rgba(255,0,0,1) 100%)!important;

img{
	max-width: 100%;
	height: auto;
	image-rendering: -webkit-optimize-contrast;
}

// VARIA
.border {
    border: 1px solid #F0F0F0!important;
}
.overflow-hidden{
	overflow: hidden;
}
.b-radius-5{
	border-radius: 5px;
	img{
		border-radius: 5px;
	}
}
// @include media-breakpoint-up(md) {}
// @include media-breakpoint-up(lg) {}
