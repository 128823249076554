.c-dropdowns{
    display: flex;
    align-items: center;
    // margin: 0 -0.8rem 2rem;
    @include media-breakpoint-only(xs) {
        flex-wrap: wrap;
        .c-dropdown{
            &__wrapper{
                margin-bottom: 1rem;
            }
        }
    }
}
.c-dropdown {
    width: 100%;
    position: relative;
    font-size: 0.95rem;
    &__wrapper{
        flex: 1;     
        // background-color: $color5;
        // margin-left: .8rem;
        // margin-right: .8rem;
    }
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        width: 100%;
        border-right: 1px solid #eee;
        border-bottom: 1px solid #eee;
        border-left: 1px solid #eee;
        background-color: $white;
        li { 
            width: 100%;
            display: flex;
            &:before {
                display: none !important;
            }
            &.row-time{
                background-color: $color4;
                padding: 0.5rem 1rem;

                input[type='submit']{
                    border-radius: 0 4px 4px 0;
                    border: none;
                }
                input{
                    display: inline-block;
                }
            }
            label {
                width: 100%;
                margin-bottom: 0;
                padding: 0.5rem 1rem;
                transition: all 0.3s ease-out;
                cursor: pointer;
                &:hover{
                    background-color:#fafafa;
                    color: $orange;
                }
            }
            input {
                display: none; 
            }
        }
    }
    &__inner {
        display: none;
        position: absolute;
        background-color: $white;
        box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
        z-index: 100;
        width: 100%;
        height: 0;
        &.active {
            display: block;
            height: auto;
        }
        li{
            border-bottom:1px solid $gray-200;
            &:first-child{
                border-top: 1px solid $gray-500;
            }
            label{
                padding-left: 10px;
            }
        }
        // span {
            // color: orange;
            // font-size: 0.9rem;
            // vertical-align: text-top;
            // margin-top: -0.2rem;
            // display: inline-block;
        // }
    }
    &__selected {
        cursor: pointer;
        width: 100%;
        padding-right: 70px;
        padding-left: 0.8rem;
        height: 42px;
        background: $white url("../images/ico-triangle-down.svg") 95% center no-repeat;
        // background: $white url("../images/ico-toggle-dropdown.svg") 95% 60% no-repeat;
        // background: $white url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAHCAYAAAAxrNxjAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABqSURBVHgBdY+xFYAwCERDiiRlRnAVN0jJGLqJG9iyhSO4gptgzvdoeHjV8Tl4QGOM3lrrIvKkQNbPpZRTVS9mXnwIrNZ6z/6Rp3ZAH4YHgyeijQK42qDVOIuiDSYLfd7f5DelPyGMTz1/AeH5OTlJA/n/AAAAAElFTkSuQmCC")
        //     95% center no-repeat;
        border: 1px solid #D5D4D5;
        border-radius: 4px !important;
        font-weight: normal;
        display: flex;
        align-items: center;
        position: relative;
        span {
            font-size: 0.9rem;
            vertical-align: text-top;
            margin-top: -0.2rem;
            display: inline-block;
        }
    }
}

