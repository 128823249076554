@font-face {
	font-family:Gazpacho Black;src:url(../fonts/gazpacho-black.woff2) format("woff2"),url(../fonts/gazpacho-black.woff) format("woff");
    font-weight:700;
    font-style:normal;
    font-display:swap;
}

body{
    // font-family: 'Inter', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400; // 300, 400, 600, 700
    // font-style: normal;
    // font-size: 16px;
    // font-size: 1.12rem;
    color: $color1;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{ 
    font-family: Gazpacho Black,sans-serif;
	font-style: normal;
	font-weight: 400;
    margin-bottom: 1.0rem;
    color: $color1;
}
h1{
    font-size: 3.0rem;
}
@include media-breakpoint-down(md) {
    h1{
        font-size: 1.8rem;
    }
}
// CUSTOM UL WITH CHECK MARKS
.list-item-checkmark {
    padding: 0;
    li {
        display: flex;
        margin-bottom: 0.5rem;
        &:before {
            // content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAOCAYAAAAi2ky3AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAD9SURBVHgBpZLJjcJAEEVfW8ydDAYysAczZxwJzgQTCSISCMASZOB2BHBnaaq8gEEGhPlSS73p1fYNXyjD7xt+Nrr3+EICWYEbwLk7KGe0EIgvyzpOUY8OyglnDmKB7BUyZGv5VAqxhE6X9Mini3L+pzfIOG6+Gcs4043jEEiK+2cQjW7obcq/zIekSfPd045r5+XT6gXk+t4GKUDaLO287P1yEm0QHTN9h1u2QYrSGhE1bTEYyS/pvLqvDKdeYTsgDXiiwkc6PoeJqtQTnUwZpTaceuUY8ULmvoy/2ODV5a1lTWrDvfOKebzICBO5nJWnwnBBJ8PVMMto94nhLrcxbKkny9GCAAAAAElFTkSuQmCC');
            content: '';
            display: inline-block;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: lighten($orange, 20%);
            vertical-align: middle;
            margin-right: 0.5rem;
            margin-top: 7px;
            line-height: 0;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }

}
a{
    color: $orange;
    &:hover{
        text-decoration: underline;
        color: $color1;
    }
    &.text-muted{
        color:$gray-500;
        font-size: 0.95rem;
    }
}
.xsmall {
    font-size: 70% !important;
    font-weight: 400;
}
.table{
    color: $color3;
}

// CUSTOM UL WITH CHECK MARKS
.umb--editor{
    ul {
        // padding: 0;
        padding-left: 15px;
        li {
            display: flex;
            align-items: center;
            margin-bottom: 0.5rem;
            &:before {
                // content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAOCAYAAAAi2ky3AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAD9SURBVHgBpZLJjcJAEEVfW8ydDAYysAczZxwJzgQTCSISCMASZOB2BHBnaaq8gEEGhPlSS73p1fYNXyjD7xt+Nrr3+EICWYEbwLk7KGe0EIgvyzpOUY8OyglnDmKB7BUyZGv5VAqxhE6X9Mini3L+pzfIOG6+Gcs4043jEEiK+2cQjW7obcq/zIekSfPd045r5+XT6gXk+t4GKUDaLO287P1yEm0QHTN9h1u2QYrSGhE1bTEYyS/pvLqvDKdeYTsgDXiiwkc6PoeJqtQTnUwZpTaceuUY8ULmvoy/2ODV5a1lTWrDvfOKebzICBO5nJWnwnBBJ8PVMMto94nhLrcxbKkny9GCAAAAAElFTkSuQmCC');
                // display: block;
                content: "";
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: #FFAFAE;
                
                // vertical-align: middle;
                margin-right: 0.5rem;
                // margin-top: 0.2rem;
                line-height: 0;
            }
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}


// CUSTOM BREADCRUMBS
.breadcrumb{
    background-color: transparent;
    margin-bottom: 1rem;
    padding-left: 0;
    padding-right: 0;
    ul{
        display: inline-flex;
        align-items: center;
        margin: 0;
        padding: 0;
        list-style: none;
        color: $white;
        li{
            a{
                color: $white;
                padding: .15rem .3rem;
                font-size: 0.85rem;
                line-height: 100%;
                &:after{
                    content: '/';
                    display: inline-block;
                    margin: 0 0rem 0 .8rem;
                    height: 10px;
                    width: 10px;
                    font-style: oblique;
                }
                &:hover{
                    text-decoration: underline;
                }
            }
            &:last-child{
                a{
                    &:hover{
                        text-decoration: none;
                        cursor: default;
                    }
                    &:after{
                        display: none;
                    }
                }
            }
            &.back{
                a{
                    display: flex;
                    align-items: center;
                    outline: 1px solid $white;
                    border-radius: 2px;
                    margin-right: 1rem;
                    &:after{
                        display: none;
                    }
                    &:hover{
                        text-decoration: none;
                        background-color: darken($orange,6%);
                    }
                    img{
                        margin-right: .3rem;
                    }
                }
            }
        }
    }
    @include media-breakpoint-only(xs) {
        display: none;
    }
}

