.arrow-right{
    display: inline;
    &:after{
        display: inline-block;
        content: url('../images/arrow-right-orange.svg');
        width: 6px;
        margin-left: 5px;
        margin-bottom: 4px;
        vertical-align: middle;
        margin-right: 4px;
        line-height: 0; 
    }
}


.icons-before{
    padding: 0;
    list-style: none;
    li{
        margin-bottom: 1rem;
        font-weight: 600;
        &:before{
            vertical-align: middle;
            margin-right: 9px;
            line-height: 0;
        }
    }
    .ico-check-green:before{ content: url('../../assets/images/ico-check-green.svg');}
    .ico-check-circle:before{ content: url('../../assets/images/ico-check-circle.svg');}
    .ico-payment:before{ content: url('../../assets/images/ico-payment.svg');}
    .ico-aanpak:before{ content: url('../../assets/images/ico-aanpak.svg');}
    .ico-enveloppe:before{ content: url('../../assets/images/ico-enveloppe.svg');}
    .ico-phone:before{ content: url('../../assets/images/ico-phone.svg');}
}