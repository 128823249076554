.navbar{
    padding: 0;
}
.navbar-expand-lg .navbar-collapse.content-filter{
    display: none !important;
}
.p-nav {
    flex-direction:column;
    align-items: flex-end;
    &.p-nav--top{
        align-items: flex-end; // right alignment Minornav - nog proper te zetten door te verplaatsen naar header
    }
    &__top{
        display: flex;
        margin-bottom: 1rem;	
        ul {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                margin: 0 0.1rem;
                a {
                    color: $color1;
                    text-decoration: none;
                    padding: 0.5rem;
                    line-height: normal;
                    &:hover {
                        text-decoration: underline;
                        color: $dark;
                    }
                }
                &:last-child{
                    a{
                        padding-right: 0;
                    }
                }
                &.active {
                    a {
                        color: $orange;
                    }
                }
            }
        }
    }
    &__lang{
        border-left: 1px solid $gray-500;
        padding-left: .5rem !important;
        margin-left: .5rem !important;
        li{
            &:last-child{
                a{
                    padding-right: 0;
                }
            }
            &.active {
                a {
                    font-weight: bold;
                    // text-decoration: underline;
                }
            }
        }
    }
    &__main {
        display: flex;
        width: 100%;
        ul {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            list-style: none;
            margin: 0 auto 0 0;
            padding: 0;
            li {
                margin: 0 0.1rem;
                a {
                    text-decoration: none;
                    padding: 0.5rem;
                    line-height: normal;
                    &:hover {
                        color: $dark;
                        text-decoration: underline;
                    }
                }
                &.active {
                    a {
                        font-weight: bold;
                        // text-decoration: underline;
                    }
                }
            }
        }
    }
    &__side{
        margin: -0.5rem 0 0 0;
        padding: 0;
        list-style: none;
        text-align: right;
        li{
            // margin-bottom: .5rem;
            margin: 0.5rem 0;
            a{
                display: block;
                font-family: Gazpacho Black,sans-serif;
                font-style: normal;
                font-weight: 400;
                padding: 0.2rem 0;
                color: $color1;
                // outline: 1px dashed #ccc;
                &:hover{
                    color: $orange;
                    text-decoration: none;
                }
            }
            &.active {
                a {
                    border-left-color: #334155;
                    color: $orange;
                }
            }
        }
    }
}

@include media-breakpoint-up(md) { 
    .navbar-expand-md .navbar-collapse.content-filter{
        display: none !important;
    }
}
@include media-breakpoint-only(xs) {
    .navbar{
        align-items: flex-start;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 999;
        padding: 0 1rem !important;
        background-color: $white;
		box-shadow: 0 10px 10px rgba($gray-500, 0.2);
        .navbar-toggler{ // ICONS & FILTER MENU #############################
            order: 2;
            position: relative;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-right: 0;
            border: 0;
            margin-top: 5px;
            &.navbar-filter,
            &.navbar-menu{
                width: 38px;
                height: 45px;
                background-color: #fff;
                padding: 0;
                margin-left: 15px;
            }
            &:after{
                position: relative;
                top: 3px;
                content: 'MENU';
                color: $orange;
                font-size: 11px;
            }
            &.navbar-filter{ // ICO FILTER ################################
                &:after{
                    position: relative;
                    top: 3px;
                    content: 'FILTER';
                    color: $orange;
                    font-size: 11px;
                }
                &.active{
                    position: absolute;
                    z-index: 9;
                    right: 1rem;
                    &:after{
                        content: 'SLUIT';
                    }
                    .navbar-filter-toggler-icon{
                        &:after{
                            content: url('../images/ico-close.svg');
                        }
                    }
                }
            }
            &:focus{
                outline: none !important;
            }
            &.active{
                .navbar-toggler-icon{
                    background: transparent;
                    &:before{
                        transform: translateX(4px) translateY(-2px) rotate(45deg);
                    }
                    &:after{
                        transform: translateX(4px) translateY(-2px) rotate(-45deg);
                    }
                }
            }
            // ICONS
            .navbar-toggler-icon{
                position: relative;
                display: inline-block;
                width: 32px;
                height: 24px;
                &:after{
                    content: url('../images/ico-menu.svg');
                }
            }
            .navbar-filter-toggler-icon{
                position: relative;
                display: inline-block;
                width: 32px;
                height: 24px;
                &:after{
                    content: url('../images/ico-filter.svg');
                }
            }
            // ACTIVE STATE
            &.navbar-menu.active{
                position: absolute;
                z-index: 9;
                // background-color: lime;
                right: 1rem;
                &:after{
                    content: 'SLUIT';
                }
                .navbar-toggler-icon{
                    &:after{
                        content: url('../images/ico-close.svg');
                    }
                }
            }
        }
        .navbar-brand{
            margin-right: auto;
            flex: none;
            order: 1;
        }
        &:focus{
            outline: none !important;
        }
        .navbar-collapse{ // UITKLAPPANEEL
            overflow: scroll;
            background-color: white;
            &.content-filter{
                order: 3;
                // position: absolute;
                // top: 60px;
            }
            &.p-nav{
                order: 4;
                z-index: 999;
                // margin-top: 1rem;
                // margin-bottom: 1rem;
                font-family: Gazpacho Black,sans-serif;
                font-style: normal;
                font-weight: 400;
                .text-right{
                    text-align: left !important;
                }
                .account{
                    margin-bottom: 1rem;
                    a{
                        background-color: $orange;
                        color: $white;
                        padding: 11px 18px 9px;
                        border-radius: 24px;
                    }
                }
            }
            
        }
        > .form-inline{
            order: 3;
            display: flex;
            flex-wrap: nowrap;
        }
        .p-nav {
            order: 4;
            display: flex;
            align-items: center;
            &__top{
                margin: 1rem 0;
                ul{ order: 2;}
            }
            &__main{
                margin-bottom: 1rem;
            }
            &__top,
            &__main{
                flex-direction: column;
                justify-content: center;
                width: 100%;
                ul{
                    flex-direction: column;
                    justify-content: center;
                    margin: 0;
                    li{
                        margin: 0.2rem auto;
                        width: 100%;
                        a{
                            display: inline-block;
                            padding: .45rem 0!important;
                            // padding: 0.5rem 0.75rem !important;
                            // background-color: $gray-200;
                        }
                        &:first-child{
                            margin-left: 0;
                            a{
                                padding-left: 0 !important;
                            }
                        }
                    }
                }
            }
            .form-inline{
                flex-wrap: nowrap;
            }
        }
    }
}