.partner-list{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    &__item{
        padding-left: 0;
        padding-right: 0;
        width: auto;
        a{
            height: 2.625rem;
            width: auto;
            display: block;
            img{
                margin-left: auto;
                margin-right: auto;
                height: 100%;
                width: auto;
            }
        }
    }
    @include media-breakpoint-only(xs){
        margin-top: .5rem;
        margin-bottom: .9rem;
        flex-wrap: wrap;
        gap: 1rem;
        &__item{
            width: calc(50% - 1rem);
            text-align: center;
            a{
                height:inherit;
                max-height: 3rem;
                padding: 0.5rem;
                display: inline-flex;
                align-items: center;
            }
        }
    }
}