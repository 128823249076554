// BUTTONS
.btn{
    border-radius: 21px;
    padding: .5rem .9rem;
    text-decoration: none;
    // font-size: 0.88rem;
    &-noradius{
        border-radius: 0;
    }
    &.btn-lg{
        font-size: inherit;
        padding: .8rem .75rem;
    }
    &.btn-xl{
        font-size: inherit;
        padding: 1.5rem 3rem;
        border-radius: 38px;
    }
    @include media-breakpoint-down(md) {
        &.btn-xl{
            padding: 1.5rem .5rem !important;
        }
    }
    &.btn-sm{
        padding: .4rem .75rem;
    }
    &:focus{
        outline: none;
        box-shadow: none;
    }
    &.btn-theme{
        border-color: $green ;
        background-color: $green ;
        color: white ;
        &:hover{
            color: white ;
            border-color: darken($green,10%);
            background-color: darken($green,10%) ;
            text-decoration: none !important;
        }
    }
    &.btn-orange{
        border-color: $orange ;
        background-color: $orange ;
        color: white ;
        &:hover{
            color: white ;
            border-color: darken($orange,10%);
            background-color: darken($orange,10%) ;
            text-decoration: none !important;
        }
    }
    &-dark{
        border-color: $color3 ;
        background-color: $color3;
        color: white ;
        &:hover{
            color: white ;
            border-color: lighten($color3,15%);
            background-color: lighten($color3,15%) ;
            text-decoration: none !important;
        }
    }
    &-blue{
        border-color: $blue ;
        background-color: $blue;
        color: white ;
        &:hover{
            color: white ;
            border-color: lighten($blue,15%);
            background-color: lighten($blue,15%) ;
            text-decoration: none !important;
        }
    }
    &.btn-link{
        color: $orange;
        text-decoration: underline;
        text-transform: uppercase;
        &:hover{
            color: $color1;
        }
    }
    &.btn-theme-outline{
        border-color: $orange ;
        background-color: $white ;
        color: $orange;
        font-weight: 600;
        &:hover{
            border-color: darken($orange,10%);
            color: darken($orange,10%);
            text-decoration: none !important;
        }
        &-transparent{
            background-color: transparent;
        }
    }
    &.btn-white{
        border-color: $white ;
        background-color: $white ;
        color: $color3 ;
        &:hover{
            background-color: lighten($color2,45%);
            border-color: lighten($color2,45%);
            color: darken($color3,20%);
            text-decoration: none !important;
        }
    }
}

@include media-breakpoint-down(lg) {
    .btn{
        &.btn-xl{
            padding: 1.5rem;
        }
    }
}
@include media-breakpoint-only(xs) {
    .btn-angle-right{
        align-items: initial;
        &:after{
            margin-top: 1px;
        }
    }
}

