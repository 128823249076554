.stick-to-card-deck{
    h2{
        display: inline-block;
        background-color: $green;
        margin-bottom: 0;
        color: $white;
        padding: .5rem 1rem;
        font-size: 1.15rem;
    }
}

.card-deck{
    display: flex;
    gap: 1.5rem;
    // margin-left: -15px;
    // margin-right: -15px;
    // .card{
    //     margin: 0 1rem;
    // }
    &.cards-per-3{
        flex-wrap: wrap;
        .card{    
            flex-basis: 100%;
            max-width: calc(33.33% - 1rem);
            margin-bottom: 0;
        }
    }
    // &.cards-per-2{
    //     flex-wrap: wrap;
    //     .card{    
    //         flex-basis: 100%;
    //         max-width: calc(50% - 2rem);
    //         margin-bottom: 0;
    //     }
    // }
}
@include media-breakpoint-only(md) { // tablet portrait
    .card-deck{
        gap: 1rem;
        &.cards-per-3{
            .card{ max-width: calc(33.33% - 0.7rem);}
            .card-footer a.btn{ display: block;}
        }
    }
}
@include media-breakpoint-only(xs) {
    .card-deck{
        margin-left: 0;
        margin-right: 0; 
        flex-wrap: wrap;
        flex-direction: column;
        &.cards-per-3, &.cards-per-2{
            .card{
                margin-bottom: 2rem;
            }
        }
        .card{    
            flex-basis: 100%;
            max-width: none !important;
            margin: 0;
            margin-bottom: 15px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    } 
}

.card{
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    // justify-content: space-between;
    position: relative;
    flex: 1;
    margin-bottom: 1.5rem;
    padding:0;
    // box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    background-color: $white;
    // border-radius: 5px 5px 0 0;
    border-radius: 5px;
    overflow: hidden;
    transition: background-color 0.5s ease;
    &.bg-light,
    &:hover{
        background-color: $color5;
    }
    .card-header{
        padding: 1rem 1rem 0  1rem;
        background-color: #E2E8F0;
    }
    .card-body{
        flex: 1 1 auto;
        min-height: 1px;
        padding: 2rem 2rem 0  2rem;
        // border-left: 1px solid #E2E8F0;
        // border-right: 1px solid #E2E8F0;
        .event--info{
            display: flex;
            margin-top: -28px;
            margin-bottom: 1rem;
            text-transform: uppercase;
            font-size: .75rem;
            line-height: normal; 
            .event-date{
                display: flex;
                align-items: center;
                background-color: $green;
                padding: 7px 12px;
                padding-right: 24px;
                color: $white;
                border-top-left-radius: 15px;
                border-bottom-left-radius: 15px;
            }
            .event-type{
                display: flex;
                margin-left: -15px;
                align-items: center;
                background-color: $white;
                padding: 7px 12px;
                border-top-left-radius: 15px;
                border-bottom-left-radius: 15px;
            }
        }
        h3{
            font-family: 'Inter', sans-serif;
            font-weight: 700;
            font-size: 1.25rem;
        }
    }
    @include media-breakpoint-down(lg) {
        .card-body{
            padding: 1rem 1rem 0 1rem;
        }
    }
    .card-footer{
        padding: 0 2rem 2rem;
        // border-left: 1px solid #E2E8F0;
        // border-bottom: 1px solid #E2E8F0;
        // border-right: 1px solid #E2E8F0;
    }
    .image img{
        transition: all 0.2s ease;
    }
    // &:hover{
    //     box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
    //     h2, h3{ color: $color2;}
    //     .image img{
    //         transform: scale(1.05);
    //     }
    // }

    // .stretched-link{        
    //     padding: 0 1.5rem 1rem;
    //     text-decoration: none;
    //     font-weight: 700;
    //     color: #575757 ;
    //     &:after {
    //         position: absolute;
    //         top: 0;
    //         right: 0;
    //         bottom: 0;
    //         left: 0;
    //         z-index: 1;
    //         pointer-events: auto;
    //         content: "";
    //         background-color: transparent;
    //         order: 2;
    //     }
    //     &:before{
    //         position: relative;
    //         content: url('../../assets/images/angle-right-grey.svg');
    //         display: inline-block;
    //         width: 6px;
    //         margin-right: 5px;
    //         margin-bottom: 2px;
    //         vertical-align: middle;
    //     }
    //     &:hover{
    //         color: $color2;
    //         text-decoration: none;
    //         &:before{
    //             content: url('../../assets/images/angle-right-blue.svg');
    //         }
    //     }
    // }
    // .icon{
    //     overflow: hidden;
    //     padding: 1.5rem 0 0 1.5rem;
    //     img{
    //         height: 40px;
    //     }
    // }
    // .image{
    //     padding: 0;
    //     overflow: hidden;
    //     img{
    //         border-radius: 15px 15px 0 0;
    //     }
    // }
}
@include media-breakpoint-only(xs) {
    flex-direction: column;
    .card{
        flex-basis: 100%;
        max-width: none !important; 
        margin-bottom: 1.5rem;
    }
}