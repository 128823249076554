.pagination{
    margin-top: 1rem !important;
    margin-bottom: 1rem;
    display: inline-flex;
    margin: auto;
    padding-left: 0;
    list-style: none;
    li{
        position: relative;
        margin-left: -1px;
        line-height: 1.25;
        color: #007bff;
        background-color: #fff;
        border: 1px solid #dee2e6;
        border-radius: 5px;
        margin-right: .6rem;
        padding-left: 0 !important; // disable padding in _typo.scss
        transition: all 0.2s ease;
        a{
            display: block;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 34px;
            height: 34px;
            text-decoration: none;
            // background-color: #fff;
        }
        &:hover{
            background-color: #575757;
            border-color: #575757;
            a{
                color: $white;
            }
        }
        &:before{
            display: none !important;
        }
        &.active{
            background-color: #575757;
            border-color: #575757;
            a{
                color: $white;
            }
        }
        &.previous {
            // border: none;
            width: auto;
            background: center center no-repeat url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA4IDE0Ij48cGF0aCBkPSJNNyAxTDEgN2w2IDYiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzU3NTc1NyIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4=');
            background-size: 20%;
            &:hover{
                background-color: #eee;
            }
        }
        &.next{
            width: auto;
            // border: none;
            background: center center no-repeat url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA4IDE0Ij48cGF0aCBkPSJNMSAxM2w2LTYtNi02IiBmaWxsPSJub25lIiBzdHJva2U9IiM1NzU3NTciIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PC9zdmc+');
            background-size: 20%;
            &:hover{
                background-color: #eee;
            }
        }
        &.disabled{
            opacity: 0.3;
            &:hover{
                background-color: #fff;
            }
            a{
                pointer-events: none;
                &:hover{
                    color: $color1;
                }
            }
        }
    }
}