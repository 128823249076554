/* UI TIME SPINNER - https://jsfiddle.net/terryyounghk/2oomnchr/ */
/* UI TIME SPINNER - https://jsfiddle.net/terryyounghk/2oomnchr/ */

.ui-widget {
    font-size: 1.0rem;
  }
  
  .ui-widget-content {
    color: #495057;
  }
  
  .ui-timespinner {
    display: inline-block;
    position: relative;
    vertical-align: middle;
  
    /* height: 1.6em; */
    height: calc(1.5em + 0.5rem + 2px);
  
    /* 28px; */
  }
  
  .ui-timespinner-value {
    display: none !important;
  
      /*
  This is for debugging only:
  
  If you set the above to display:block,
  the original element should appear right above the spinner
  */
    position: absolute;
    top: -10px;
    height: 8px;
    line-height: 8px;
    font-size: 8px !important;
    width: 8ch;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  span.ui-timespinner-ampm {
    display: none;
  }
  
  .ui-timespinner-ampm span.ui-timespinner-ampm {
    display: block;
  }
  
  span {
    &.ui-timespinner-hh, &.ui-timespinner-mm, &.ui-timespinner-ampm {
      /* height: 1.6em; */
      height: 2.1em;
    }
  }
  
  .ui-timespinner .ui-spinner {
    position: absolute;
    border-color: transparent !important;
    background-color: transparent;
    background: transparent;
    overflow: visible;
  }
  
  .filter .ui-timespinner input[type='text'],
  .ui-timespinner input[type='text'] {
    border: none !important;
    border-radius: 0 !important;
    font-size: 0.9rem !important;
    color: #474344 !important;
  }
  
  .ui-timespinner .ui-spinner-input {
    /* width: 2ch; */
    width: 2.3ch;
    padding: 3px 0 0 0 !important;
    margin: 0.2em;
  }
  
  /* .ui-timespinner .ui-spinner input[type='text'] {
      outline: 1px solid red !important;
  } */
  
  .ui-timespinner-colon {
    position: absolute;
    width: 1ch;
    padding: 0;
    margin: 0.2em;
  
    /* left: 2.5ch; */
    left: 2.4ch;
    z-index: 10;
  
    /* font-size: 0.9rem; */
  }
  
  .ui-state-disabled .ui-timespinner-colon {
    opacity: .35;
    filter: Alpha(Opacity = 35);
    background-image: none;
  }
  
  .ui-timespinner {
    width: 8ch;
    min-width: 8ch;
  
    &.ui-timespinner-ampm {
      width: 11ch;
      min-width: 11ch;
    }
  
    .ui-spinner-button {
      width: 1.6ch;
    }
  }
  
  span.ui-timespinner-hh {
    left: 0;
  }
  
  .ui-timespinner-hh .ui-spinner-button {
    right: -7.6ch;
  }
  
  .ui-timespinner-ampm .ui-timespinner-hh .ui-spinner-button {
    right: -10.4ch;
  }
  
  span.ui-timespinner-mm {
    left: 3.3ch;
  }
  
  .ui-timespinner-mm .ui-spinner-button {
    right: -4.8ch;
  }
  
  .ui-timespinner-ampm .ui-timespinner-mm .ui-spinner-button {
    right: -7.6ch;
  }
  
  span.ui-timespinner-ampm {
    left: 5.7ch;
  }
  
  .ui-timespinner .ui-spinner-input.ui-timespinner-ampm {
    width: 2.6ch;
  }
  
  .ui-timespinner-ampm {
    .ui-spinner-button {
      right: -7.6ch;
    }
  
    .ui-timespinner-ampm .ui-spinner-button {
      right: -5.4ch;
    }
  }
  
  input {
    &.ui-timespinner-hh, &.ui-timespinner-mm, &.ui-timespinner-ampm {
      position: absolute;
      border-color: transparent;
      background-color: transparent;
      background: transparent !important;
      overflow: visible;
      z-index: 2;
    }
  }
  
  .ui-timespinner .ui-spinner {
    &.ui-timespinner-ampm a.ui-spinner-button {
      display: block;
      margin-right: 5px;
    }
  
    &.ui-timespinner-mm a.ui-spinner-button {
      display: block;
      margin-right: 4px;
    }
  
    a {
      &.ui-spinner-up {
        margin-top: -1px;
      }
  
      &.ui-spinner-down {
        margin-bottom: 1px;
      }
    }
  }
  
  input {
    &.ui-timespinner-hh, &.ui-timespinner-mm, &.ui-timespinner-ampm {
      position: absolute;
      border-color: transparent;
      background-color: transparent;
      background: transparent;
      overflow: visible;
      z-index: 2;
    }
  
    &.ui-timespinner-hh {
      text-align: right;
    }
  
    &.ui-timespinner-mm {
      text-align: left;
    }
  
    &.ui-timespinner-colon, &.ui-timespinner-ampm {
      text-align: center;
    }
  }