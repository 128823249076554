
// FORMS
.form{
    label{
        color: $color1;
        font-weight: 500;
        // font-size: 0.9rem;
    }
}
.form-group{
    margin-bottom: 1.5rem;
}
.form-control{
    height: auto;
}
input[type=text],
input[type=email],
input[type=password],
select{
    border: none;
    border-radius: 0 !important;
    border: none !important;
    background-color: #F4F5F4 !important;
    padding: .625rem .75rem !important; 
    &:focus{
        outline: none; box-shadow: none;
        background-color: #F4F5F4;  
    }
}
.form-control:focus{
    background-color: #F4F5F4;  
}

.c-form__label{
    font-weight: 500;
    color: $color1;
}


//////////////////////////////
.custom-control{
    label, input{
        &:hover{
            cursor: pointer;
        }
    }
}
.custom-control-inline{
    margin-right: 0.5rem;
}
.custom-checkbox{
    label{
        &:hover{
            cursor: pointer;
        }
    }
}
.custom-select{
    background: no-repeat 98% center url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDFMNSA1TDkgMSIgc3Ryb2tlPSIjNzM3QjdEIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=');
    &:hover{
        cursor: pointer;
    }
    &:focus{
        outline: none;
        box-shadow: none;
    }
}
.custom-control-input:checked~.custom-control-label:before{
    border-color: $orange;
    background-color: $orange;
}


// ZAAL RESERVEREN
.dagdeel{
    margin-left: 0;
    margin-right: 0;
    margin-bottom: .2rem;
    display: flex;
    justify-content: space-between;
    > div{
        padding-left: 5px;
        padding-right: 0;
        flex:1;
        align-items: center;
        // outline: 1px solid red;
        label{
            font-size: 0.95rem;
        }
        .custom-control{
            padding-left: 1.2rem;
            display: flex;
            align-items: center;
        }
        .custom-control-label:after, .custom-control-label:before{
            left: -1.2rem;
        }
        .custom-control-inline{
            margin-right: 0;
        }
    }
    @include media-breakpoint-only(xs) {
        flex-wrap: wrap;
        > div{
            flex-basis: 100%;
            .custom-control{
                padding: 0.3rem 1.5rem;
                border-bottom: 1px solid #fff;
            }
            &:first-child{
                background-color: #F0F0F0;
            }
        }
    }
}

// RESERVERING HERHALEN
.repeat{
    .repeat--content{
        margin-top: .5rem;
        background-color: #f0f0f0;
    }
    .nav-tabs{
        padding: .5rem 1rem;
        .custom-control{
            label{
                display: block;
                padding-top: .2rem;
                padding-bottom: .2rem;
                &:before, &:after{
                    top: .5rem
                }
            }
        }
    }
    .tab-content{
        padding: 1rem;
        height: 100%;
        background-color: #fafafa;
        border: 1px solid #d5d4d5;
        .c-dropdown__wrapper{
            width: 100%;
        }
        .c-dropdown__selected{
            background-position: 85%;
        }
    }
}

// WEEKDAY SELECTORS --- https://codepen.io/steelwater/pen/BjeZQx
    .weekDays-selector{
        margin-top: .5rem;
        margin-bottom: .5rem;
    }
    .weekDays-selector input {
        display: none!important;
    }
    .weekDays-selector input[type=checkbox] + label {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: #fff;
        border: 1px solid #d5d4d5;
        height: 50px;
        width: 50px;
        margin-right: 3px;
        line-height: 40px;
        font-weight: bold;
        text-align: center;
        cursor: pointer;
    }
    .weekDays-selector input[type=checkbox]:checked + label {
        background: #f0f0f0;
    }
    input[type='time']{
        box-shadow: none;
        padding-left: .5rem;
        padding-right: .5rem;
        // text-align: center;
    }
    // input[type="time"]::-webkit-calendar-picker-indicator {
    //     background: none;
    //     display:none;
    // }
    .time{
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        span.doublepoint{
            background-color: #fff;
            border-top: 1px solid #ced4da;
            border-bottom: 1px solid #ced4da;
            padding: 0.375rem 0;
        }
        input[type='number']{
            position: relative;
            &:first-child{
                // outline: 2px solid red; 
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-right: 0;
                text-align: right;
            }
            &:last-child{
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-left: 0;
            }
        }
        &.input-group{
            span.doublepoint{
                height: calc(1.5em + 0.5rem + 0px);
            }
        }

        .btn{
            height: calc(1.5em + 0.5rem + 2px);
        }
    }
    @include media-breakpoint-only(xs) {
        .weekDays-selector{
            display: flex;
            justify-content: space-between;
        }
        .weekDays-selector input[type=checkbox] + label{
            height: 36px;
            width: 36px;
        }
    }


// GEGEVENS
    .gegevens{
        .row{
            margin-bottom: .5rem;
        }
        &--label{
            display: block;
            font-weight: 600;
        }
    }

// ORANGE COLLAPSE BUTTON
.btn-collapse{
    position: relative;
    color: $orange;
    font-weight: 700;
    &:hover{
        text-decoration: none;
        color: $orange;
    }
    &:after{
        position: absolute;
        top: 9px;
        margin-left: 4px;
        display: inline-flex;
        align-items: center;
        content: url('../images/ico-triangle-orange.svg');
        line-height: 0; 
    }
    &[aria-expanded="true"]{
        &:after{
            transform: rotate(180deg);
            top: 6px !important;
        }
    }
}

// AANMELDEN
	.aanmelden{
		display: flex;
        align-items: center;
        height: 60vh; 
        // min-height: 800px; 
        .bgwhite{
            box-shadow: 0 10px 10px rgba( #222, 0.25) !important;
        }
	}
    @include media-breakpoint-only(xs){
        .aanmelden{ 
        }
    }


// ERROR
.field-validation-error{
    display: block;
    width: 100%;
    color: #dc3545!important;
    font-size: 0.8rem;
    font-weight: 700;
}